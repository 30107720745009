<template>
    <div class="container has-space">
        <MobileHeaderBack ></MobileHeaderBack>
        <div v-if="showOnMobile"><img class="w-100" src="../../../static/images/signup_bg_webapp_1.png"></div>
        <div class="signup-wrapper">          
           <SignupThemeInfo v-if="!isPopUp"></SignupThemeInfo>
             <div class="signup-form__wrapper">
                <transition name="slide2">
                
                    <div v-if="loginNextStep === 99" class="mw-100">
                          <!-- <form action="#" id="my_captcha_form">
                            <div class="g-recaptcha" 
                        data-sitekey="6Lfx0q4fAAAAAOC0PtH0VNThLWKP9NJO36mmHSef" 
                        ></div>
                            <p>
                            <button type="submit" >Submit</button>
                            </p>
                        </form> -->

                             <!-- <div class="g-recaptcha" data-sitekey="6Lfx0q4fAAAAAOC0PtH0VNThLWKP9NJO36mmHSef"></div>  -->
                        <h1 v-if="showOnMobile" class="signup-form__title">{{$t('WELCOME')}}</h1>
                        <h2 v-else class="signup-form__title mb-main">{{$t('LOGIN')}}</h2>
                        <div class="signin-info error" v-if="showInfoErrorMsg">
                            <img class="mr-1rem pointer" @click="showInfoErrorMsg = false"  src="../../../static/images/icons/icon_close.png">
                            <span class="font-m">{{ $t('LOGIN_INFO_ERROR') }}</span>
                        </div>
                        <!-- phone number -->
                        <div class="form-group">
                            <label class="form-label">{{$t('PHONE_NUMBER')}}</label>
                            <div class="display-flex relative">
                                <div class="display-flex-center signup-form__phone-prefix" @click="handleDropdown($event)">
                                    <span class="mr-1rem">+{{selectedCountry.callingCode}}</span>
                                    <img src="../../../static/images/icons/icon_arrow_down_white.png">                                          
                                </div>
                                <Dropdown ref="dropdownRef" :data="countryList" @dropDownChange="dropDownChange($event)"></Dropdown>
                                <input class="form-control" v-model="phoneNumber" pattern="[0-9]*" type="text" v-on:keyup="inputPhoneNumberEvent" v-on:keyup.enter="handleLoginStep()" :placeholder="$t('PHONE_NUMBER')">
                            </div>
                            <div class="form-tips">
                                <div>(+{{selectedCountry.callingCode}})
                                    <span v-if="selectedCountry.callingCode === 66">098765432</span>
                                    <span v-else-if="is10PhoneNo">1234567890</span>
                                    <span v-else-if="is11PhoneNo">12345678901</span>
                                    <span v-else>123456789</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div v-if="isPopUp" @click="handlePopUpSignUp('forgetPassword')">
                                 <div class="signin-form__forgetpassword">{{$t('FORGOT_PASSWORD')}}</div>
                            </div>
                            <router-link v-else :to="{ name: 'forgetpassword' , query: { callBackUrl: callBackUrl }}" class="signin-form__forgetpassword">{{$t('FORGOT_PASSWORD')}}</router-link>
                        </div>
                        <!-- Sign In -->
                        <div class="form-signup__btn-wrapper">
                            
                            <div class="mb-1rem">
                                <button class="form-control signup-form__disable" :disabled="isLoading" :class="{'signup-form__enable' : isSignInBtnEnable}" @click="handleLoginStep()">{{$t('LOGIN')}}</button>
                            </div>

                            <div class="display-flex-center mb-1rem">
                                <span class="signup-form__seperator-line"></span>
                                <span class="signup-form__seperator-or">{{$t('OR')}}</span>
                                <span class="signup-form__seperator-line"></span>
                            </div>
                            <div class="mb-1rem">
                                <div v-if="isPopUp" @click="handlePopUpSignUp('signUp')" ><button class="form-control btn-outline pointer" :class="{'signup-form__enable': isSignInBtnEnable }" >{{$t('SIGNUP')}}</button></div>
                                <router-link v-else :to="{ name: 'signup' , query: { callBackUrl: callBackUrl }}"><button class="form-control btn-outline pointer" :class="{'signup-form__enable': isSignInBtnEnable }" >{{$t('SIGNUP')}}</button></router-link>
                            </div>
                            <!-- 
                            <div class="mb-05rem">
                                <button class="form-control pointer btn-line mb-05rem display-flex-center" @click="signInWithLINE()">
                                    <div class="mr-05rem btn-icon">
                                        <img class="w-100 h-100" src="../../../static/images/icons/social_media/icon_line_colored.png">
                                    </div>
                                    <span>{{$t('CONTINUE_WITH_LINE')}}</span>
                                </button>
                                <button class="form-control pointer btn-zalo  display-flex-center" @click="signInWithZalo()">
                                    <div class="mr-05rem btn-icon">
                                        <img class="w-100 h-100" src="../../../static/images/icons/social_media/icon_zalo_colored.png">
                                    </div>
                                    <span>{{$t('CONTINUE_WITH_ZALO')}}</span>
                                </button>
                            </div> -->
                            <!-- <div class="tc font-m td-underline signup-form__route">
                                <router-link :to="{ name: 'signup'}" >{{$t('DONT_HAVE_ACCOUNT_SIGNUP')}}</router-link>
                            </div> -->
                        </div>
                    </div>
                </transition>

                <!-- loginMethod: enter OTP -->
                <transition name="slide2">
                    <div v-if="loginNextStep === 1 || loginNextStep === 2" class="mw-100">
                        <h1 v-if="showOnMobile" class="signup-form__title">{{$t('WELCOME')}}</h1>
                        <h2 v-else class="signup-form__title mb-main">{{$t('ENTER_OTP')}}</h2>
                        <div class="signin-info error" v-if="showInfoErrorMsg">
                            <img class="mr-1rem pointer" @click="showInfoErrorMsg = false"  src="../../../static/images/icons/icon_close.png">
                            <span class="font-m">{{$t('LOGIN_INFO_ERROR')}}</span>
                        </div>
                        <!-- otp -->
                        <div class="form-group">
                            <label class="form-label">{{$t('OTP')}}</label>
                            <div class="display-flex">
                                <input class="form-control mr-1rem flex-3"  ref="otpRef"  type="text"  v-model="otp.inputValue" v-on:keyup="inputOTPEvent" v-on:paste="inputOTPEventPaste" v-on:keyup.enter="postSigninDataOTP()" :placeholder="$t('ENTER_OTP')">
                                <div class="" >
                                    <button v-if="otp.isShowSendOTP" class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableSendOtpBtn }" @click="handleGetSignupCode('send')">{{$t('SEND_OTP')}}</button>
                                    <button v-else class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableResendOtpBtn }" @click="handleGetSignupCode('resend')">{{$t('RESEND')}}<span v-if="!otp.isEnableResendOtpBtn"> ({{ otp.currentResetTime }}s)</span></button>
                                </div>
                            </div>
                            <div class="form-tips">
                                <div v-if="!otp.isShowSendOTP && !otp.isError">{{$t('OTP_TIP')}}</div>
                                <div v-if="otp.isError" class="error">{{$t('OTP_ERROR_MSG')}}</div>
                            </div>
                            <div class="signup-form__recaptcha">
                                <div v-if="isVietnamSelected" id="recaptcha-container"></div>                            
                                <!-- <vue-recaptcha v-else ref="invisibleRecaptcha" @verify="onVerifyRecaptcha" size="invisible" :sitekey="recaptcha.siteKey"></vue-recaptcha> -->
                            </div>
                        </div> 
                            <div class="form-signup__btn-wrapper">
                            <div class="mb-1rem">
                                <button class="form-control signup-form__disable" :disabled="isLoading" :class="{'signup-form__enable' : otp.isEnableConfirmBtn}" @click="handleConfirmBtn()">{{$t('CONFIRM')}}</button>
                            </div>
                        </div>
                    </div>
                </transition>
                
                <!-- loginMethod: enter password -->
                <transition name="slide2">
                    <div v-if="loginNextStep === 0"  class="mw-100" >
                        <h1 v-if="showOnMobile" class="signup-form__title">{{$t('WELCOME')}}</h1>
                        <h2 v-else class="signup-form__title mb-main">{{$t('ENTER_PASSWORD')}}</h2>
                        <div class="signin-info error" v-if="showInfoErrorMsg">
                            <img class="mr-1rem pointer" @click="showInfoErrorMsg = false"  src="../../../static/images/icons/icon_close.png">
                            <span class="font-m">{{$t('LOGIN_INFO_ERROR')}}</span>
                        </div>
                         


                        <!-- password -->
                        <div class="form-group mb-main">
                            <label class="form-label">{{$t('PASSWORD')}}</label>
                            <div class="form-group__container">
                                <input class="form-control" ref="passwordRef" :type="showPassword ? 'text' : 'password'" v-model="password" maxlength="20" v-on:keyup.enter="postSigninData()" v-on:keyup="inputPasswordEvent" v-on:paste="inputPasswordEventPaste"  :placeholder="$t('ENTER_PASSWORD')">
                                <div class="display-flex-center form-icon pointer" @click="showPassword = !showPassword">
                                    <img v-if="showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                                    <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                                </div>
                            </div>

                        </div>
                                
                        <!-- forget password -->
                        <div>
                            <div v-if="isPopUp" @click="handlePopUpSignUp('forgetPassword')">
                                 <div class="signin-form__forgetpassword">{{$t('FORGOT_PASSWORD')}}</div>
                            </div>
                            <router-link v-else :to="{ name: 'forgetpassword' , query: { callBackUrl: callBackUrl }}" class="signin-form__forgetpassword">{{$t('FORGOT_PASSWORD')}}</router-link>
                        </div>
                        <div class="signin-form__OTP" @click="signInOTP()">{{$t('LOGIN_WITH_OTP')}}</div>
                        <div class="form-signup__btn-wrapper">
                            <div class="mb-1rem">
                                <button class="form-control signup-form__disable" :disabled="isLoading" :class="{'signup-form__enable' : isPasswordSignInBtnEnable}" @click="handleConfirmBtn()">{{$t('CONFIRM')}}</button>
                            </div>
                        </div>
                    </div>
                </transition>
             </div>
        </div>
       <div v-if="showOnMobile"><img class="w-100" src="../../../static/images/signup_bg_webapp_2.png"></div>
    </div>
      <!-- <Toastr ref="toastrRef" :text="toastrMsg" :type="toastrType" :position="toastrPosition"></Toastr>  -->

</template>

<script>
import Toastr from '@/components/toastr/Toastr.vue'
import SignupThemeInfo from '@/components/user/SignupThemeInfo.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import Footer from '@/components/Footer.vue'
import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import { VueRecaptcha } from 'vue-recaptcha';

import { getAuth, RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";
import { initializeApp, getApps } from "firebase/app";

import signInZalo from '@/js/user/signInZalo.js'
import config from '@/js/config.js'
import {mapGetters,mapMutations,mapActions} from 'vuex'

export default {
    props: {
        isPopUp: {
            type: Boolean,
            default: false
        },

        isShowWelcomeMsg: {
            type: Boolean,
            default: true
        }
    },

    components: {
        SignupThemeInfo,
        Dropdown,
        Footer,
        MobileHeaderBack,
        Toastr,
        VueRecaptcha 
    },
    data() {
        return {
            toastrMsg:'',
            toastrType:'',
            callBackUrl: '',
            showPassword: false,
            isPasswordSignInBtnEnable: false,
            password: '',
            showInfoErrorMsg: false,

            isSignInBtnEnable: false,
            isLoading: false,

            countryList: config.countryList.sort((a, b) => a.name.localeCompare(b.name)),
            selectedCountry: {
                callingCode:''
            },
            phoneNumber:'',
            loginNextStep: 99,
            
            loginMethod: 0, //loginMethod(0=password, 1=OTP)

            otp: {
                inputValue: '',
                isError: false,
                isEnableResendOtpBtn: false,

                isShowSendOTP: true,
                isEnableSendOtpBtn: true,

                currentResetTime: null,
                resetDuration: 120,
                
                isEnableConfirmBtn: false,
                interval: null
            },

            recaptcha: {
                value: '',
                siteKey: config.recaptchaSiteKey
            },

            isVietnamSelected: false,

            auth:null,
            appVerifier: null,
            showOnMobile: false,

            is10PhoneNo: false,
            is11PhoneNo: false,
        }
    },
    computed: {
        ...mapGetters([
            'currentLocale',
            'currentLanguageObj',
            'currentLanguageList',
            'isMobile',
            'userLangConfig',
            'userCountryCode',
        ]),
    },
    created() {
        window.addEventListener('keydown', this.listenKeypressEvent);
    },
    beforeUnmount() {
       clearInterval(this.otp.interval);
       window.removeEventListener('keydown', this.listenKeypressEvent)
    },
    mounted() {
        this.init();
        this.getCurrentLocationData();
        // this.REMOVE_USER_LANG_CONFIG();
    },

      methods: {
        ...mapMutations([
            "REMOVE_USER_LANG_CONFIG",
            "REMOVE_USER_COUNTRY_CODE",
            "SET_USER_COUNTRY_CODE"
        ]),
         ...mapActions([
            "postSignin",
            "getCurrentLocation",
            "setUserData",
            "postSigninLine",
            "getLoginMethod",
            "getWLGP",
            "postLoginOtp",
            "getContentList",
            "navigationMenuEvent",
            "popUpSignInModuleEvent",
            "toastrChangeEvent",
            "currentLocaleChangeEvent",
            "postFirebaseSignin",
            "currentCountryChangeEvent",
            "getCountryCodeByIp",
        ]),
        init() {
            this.syncCallbackUrl();
            this.showOnMobile = this.isMobile;
        },

        onVerifyRecaptcha: function (response) {           
           this.recaptcha.value = response;
           if (this.recaptcha.value !== '') {             
               if (this.otp.isShowSendOTP) {
                   this.otp.isEnableSendOtpBtn = true;
               } else {
                   this.otp.isEnableResendOtpBtn = true;
               }
           }
        },

        syncCallbackUrl() {
            let routeCallBackUrl = this.$route.query.callBackUrl;
            this.callBackUrl = routeCallBackUrl;      
        },

        async getCurrentLocationData() {
            let matchSelectedCountry = false;
            // let timeZone = this.$tools.getCurrentTimeZone();
            let countryCode;

            if (this.userCountryCode === null) {
                const result = await this.getCountryCodeByIp();
                if (result.message === "Success") {
                    countryCode = result.result.countryCode
                    this.currentCountryChangeEvent(countryCode);
                }
            } else {
                countryCode = this.userCountryCode;
            }

            // detect current location and selected current location country calling code
            this.countryList.forEach(x=> {
                // if (x.timeZone === timeZone) {
                if (x.countryCode === countryCode) {
                    this.$refs.dropdownRef.handleDropdownSelect(x);
                    matchSelectedCountry = true;
                    return false;
                }
            })

            //otherwise default to thailand calling code
            if (!matchSelectedCountry) {
                this.$refs.dropdownRef.handleDropdownSelect(this.countryList[0]);
            }
        },
   
        async postSigninData(){
            if (this.isSignInBtnEnable) {
                this.isLoading = true;
                let params = {
                    username: this.selectedCountry.callingCode + this.phoneNumber,
                    password: this.password,
                }

                const result = await this.postSignin(params);
                this.isLoading = false;
                this.signInSucuess(result);
            }
        },

        async postSigninDataOTP() {           
            let params = {
                username: this.selectedCountry.callingCode + this.phoneNumber,
                otpCode: this.otp.inputValue,
            }

            const result = await this.postLoginOtp(params);
            this.signInSucuess(result);
        },

        async postFirebaseSigninData(data) {
            let params = {
                username: this.selectedCountry.callingCode + this.phoneNumber,
                idToken: data.user.accessToken,
                preferredLanguage: this.currentLanguageObj.locale                        
            }

            const result = await this.postFirebaseSignin(params);
        
            this.signInSucuess(result);
        },
        async postSigninDataVietnam() {
            //this.handleSignup();
            const code = this.otp.inputValue;
            confirmationResult.confirm(code).then((data) => {
                this.postFirebaseSigninData(data);
            // ...
            }).catch((error) => {
               let params = {
                    isOpen:true,
                    msg: "INVALID_CODE",  
                    type:'info',
                }               
                this.toastrChangeEvent(params)
            });
        },

        handleConfirmBtn() {
            if (this.isVietnamSelected) {
                this.postSigninDataVietnam();
            } else {
                if (this.loginNextStep === 1) {
                    //otp
                    this.postSigninDataOTP();
                } else {
                    this.postSigninData();
                }                
            }
        },
    
        async getContentListData() {
            let params = {
                timeType: 2,
                language: this.currentLanguageObj.locale,
                lang: this.currentLanguageObj.apiParam,
            }
         
            const result = await this.getContentList(params);

            let returnResult = result.result;

            this.navigationMenuEvent(returnResult);
        },
       
        signInSucuess(result) {
            if (result.result.businessCode === 0) { //sucuess
                this.REMOVE_USER_COUNTRY_CODE();
                this.setUserData(result.result.data);
                let country = result.result.data.preferredCountry ?? this.countryList[0];
                let locale = result.result.data.preferredLanguage ?? this.userLangConfig[0].locale;
                locale = this.currentLanguageList.filter(x => x.locale === locale)[0].displayLocale;
                this.currentCountryChangeEvent(country);

                if (this.isPopUp) {
                    // 
                     this.$emit('loginSucuessEvent',"signIn");

                     this.currentLocaleChangeEvent(locale);

                    if (this.isShowWelcomeMsg) {
                        let params={
                            isOpen:true,
                            msg:'WELCOME_BACK'
                        }
                        this.toastrChangeEvent(params)
                    }

                    if (!result.result.data.walkthroughFlag){
                        // Enable on basketball Phase 2
                        // this.$router.push({name: "home"})
                    }
                 
                } else {
                    let callBackUrl = "";
                    let callBackUrlArray = [];
                    let addRedirectWord = "";

                    let routeCallBackUrl = this.$route.query.callBackUrl;

                    if (routeCallBackUrl) {
                        // // Enable on basketball Phase 2
                        // if (!result.result.data.walkthroughFlag) {
                        //     this.$router.push({name: "home"})
                        // } else {
                            callBackUrlArray = routeCallBackUrl.split('/');
                            callBackUrlArray[1] = locale;
                            callBackUrl =  callBackUrlArray.join('/');

                            if (callBackUrl.includes('?')) {
                                addRedirectWord = "&";
                            } else {
                                addRedirectWord = "?";
                            }
                            callBackUrl = callBackUrl + `${addRedirectWord}redirectFrom=signIn`;  
                        // }                     
                    }   

                    if (callBackUrlArray[2] !== 'event') {
                        setTimeout(() => {                               
                            let params={
                                isOpen:true,
                                msg:'WELCOME_BACK'
                            }
                            this.toastrChangeEvent(params)
                        }, 1000);
                    }                      
                    if (callBackUrl === "") {
                        this.$router.push(`/${locale}`); 
                    }else {
                        this.$router.push(`${callBackUrl}`); 
                    }
                }
               
            } else {             
                if (result.result.businessCode === 100016) {
                     let params={
                        isOpen:true,
                        msg:result.result.message,
                        type:'info'       
                    }
                    this.toastrChangeEvent(params)
                    // this.toastrMsg = result.result.message;
                    // this.toastrType = 'info';
                
                    // this.$refs.toastrRef.handleOpen(); 
                }
                this.showInfoErrorMsg = true;
            }

            this.getContentListData();
        },
        
        inputOTPEventPaste() {
             setTimeout(() => {
                 this.inputOTPEvent();
            }, 300);
          
        },
        inputOTPEvent() {
            if (this.otp.inputValue.length > 0) {
                this.otp.isEnableConfirmBtn = true;
            } else {
                this.otp.isEnableConfirmBtn = false;
            }
        },

        handlePopUpSignUp(type) {
            this.popUpSignInModuleEvent(type)
            //this.$emit('switchPageEvent',type);
        },

        otpResendCountdown() {
                this.otp.currentResetTime = this.otp.resetDuration;
                this.otp.isShowSendOTP = false;
                this.otp.isEnableResendOtpBtn = false;

                this.otp.interval = setInterval(() => {
                    if (this.otp.currentResetTime > 0) {// only positive number 
                        this.otp.currentResetTime--;
                    }
                    
                    //enable button when reset is 0
                    if (this.otp.currentResetTime === 0) {
                        this.recaptcha.value = "";
                        this.otp.isEnableResendOtpBtn = true;

                        clearInterval(this.otp.interval);
                    }
                }, 1000);
        },

        async handleGetSignupCode(type) {    
            if (this.isVietnamSelected) {
                let phoneNumber = "+" + this.selectedCountry.callingCode + this.phoneNumberVnValidation(this.phoneNumber);
                signInWithPhoneNumber(this.auth, phoneNumber, this.appVerifier)
                    .then((confirmationResult) => {
                        window.confirmationResult = confirmationResult;
                        this.otpResendCountdown();
                    }).catch((error) => {
                        let params = {
                            isOpen:true,
                            msg:  "FIREBASE_ERROR",
                            type: 'info',   
                        }
                        
                        this.toastrChangeEvent(params)
                    });
            } else {
                await this.$recaptchaLoaded()

                const token = await this.$recaptcha('homepage');
                this.recaptcha.value = token;

                let params = {
                    method: 'PHONE',
                    username: this.selectedCountry.callingCode + this.phoneNumber,
                    'g-recaptcha-response': this.recaptcha.value
                }

                const result = await this.getWLGP(params);

                if (result.result.businessCode === 0) {
                    this.otpResendCountdown();
                    if (type === 'send') {
                        this.otp.isShowSendOTP = false;
                    } 
                } else {
                    let params = {
                        isOpen:true,
                        msg:result.result.message,
                        type:'info'       
                    }
                    this.toastrChangeEvent(params);
                }
                }         
            
        },

        async handleLoginStep() {           
            let param = {
                username: this.selectedCountry.callingCode + this.phoneNumber,
            }

            const result = await this.getLoginMethod(param);
            
            if (result.result.businessCode === 0) {
                this.loginNextStep = result.result.data.loginMethod; 
                this.$nextTick(() => {
                    //0 = Password, 1 = Amazon SMS, 2 = Firebase OTP
                    if (this.loginNextStep === 0) {
                        this.$refs["passwordRef"].focus();
                    } else if (this.loginNextStep === 2) {
                        this.showFirebaseRecaptcha();
                        this.$refs["otpRef"].focus();
                    } else {
                        //get otp code

                        //this.handleGetSignupCode();
                        
                    }
                })
            } else {
                let params={
                    isOpen:true,
                    msg:result.result.message,  
                    type:'info'                   
                }
                
                this.toastrChangeEvent(params)
            }
        },

        showFirebaseRecaptcha() {
            if (this.selectedCountry.id === 'Vietnam') {
                this.isVietnamSelected = true;
                this.$nextTick(() => {
                    this.initFirebase();
                    this.otp.isEnableSendOtpBtn = true;
                })                            
            } else {
                this.isVietnamSelected = false;
            }  
        },

        signInOTP() {
            this.loginNextStep = 1;
            this.showFirebaseRecaptcha();
        },

        handleDropdown(e){
             this.$refs.dropdownRef.handleOpen(e);              
        },
        dropDownChange(e){
            this.selectedCountry = e;
            this.checkPhoneNo(this.selectedCountry.callingCode);
        },

        checkPhoneNo(callingCode) {
            this.is10PhoneNo = [55, 63].includes(callingCode) ? true : false;
            this.is11PhoneNo = [86].includes(callingCode) ? true : false;
        },

        // phone number keyup event
        async inputPhoneNumberEvent() {
            this.phoneNumber = this.$tools.onlyNumber(this.phoneNumber);
            let minLength = 6;
            let maxLength = [55, 86].includes(this.selectedCountry.callingCode) ? 11 : 10;

            if (this.phoneNumber.length > maxLength) {
                this.phoneNumber = this.phoneNumber.slice(0, maxLength)
            }

            if (this.phoneNumber.length >= minLength && this.phoneNumber.length <= maxLength ) {
                this.isSignInBtnEnable = true;
            } else {
                this.isSignInBtnEnable = false;
            }
        },

        //password keyup event
        inputPasswordEvent(){
            let passwordMinLength = 8;
            if (this.password.length >= passwordMinLength) {
                this.isPasswordSignInBtnEnable = true;
            } else {
                this.isPasswordSignInBtnEnable = false;
            }
        },

        inputPasswordEventPaste() {
            setTimeout(() => {
                 this.inputPasswordEvent();
            }, 300);
        },

        initFirebase() {
            var firebaseConfig = {
                apiKey: config.RECAPTCHA_API_KEY,
                authDomain: config.RECAPTCHA_AUTH_DOMAIN,
                projectId: config.RECAPTCHA_PROJECT_ID,
                storageBucket: config.RECAPTCHA_STORAGE_BUCKET,
                messagingSenderId: config.RECAPTCHA_MESSAGING_SENDER_ID,
                appId: config.RECAPTCHA_APP_ID,
                databaseURL: config.FIREBASE_DATABASE_URL
            };
        
            getApps().length === 0 ? initializeApp(firebaseConfig) : getApps();

            this.auth = getAuth();

            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    this.recaptcha.value = response;
                    this.otp.isEnableSendOtpBtn = true;                 
                },
                'expired-callback': (respone) => {
                    //  this.recaptcha.value = "";
                    //  this.verifyAllInput();
                }
            }, this.auth);

            recaptchaVerifier.render().then((widgetId) => {
                window.recaptchaWidgetId = widgetId;
            });

           this.appVerifier = window.recaptchaVerifier;
        },

        phoneNumberVnValidation(data) {
            //if phonumber is vn, and phone number is start from 0, then remove 0
            if (this.selectedCountry.callingCodeNumOnly !== '6') {//beside malaysia phone number
                if (data.charAt(0) === '0') {
                    return data.substring(1)
                } else {
                    return data
                }
            } else {
                return data
            }
        },
        getLineToken() {
            let length = 32;
            let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for ( let i = 0; i < length; i++ ) {
                result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            return btoa(result);
        },

        signInWithLINE() {
            let channelId = config.lineChannelId;
            let state = this.getLineToken();
            let nonce = this.getLineToken();
            let scope = "openid%20profile";

            localStorage.removeItem("state");
            localStorage.removeItem("nonce");
            localStorage.removeItem("currentLocale");

            let callbackUrl = window.location.origin + "/lineCallback";

            let encodedCallbackUrl = encodeURIComponent(callbackUrl);

            localStorage.setItem("state", state);
            localStorage.setItem("nonce", nonce);
            localStorage.setItem("currentLocale", this.currentLocale);
           
            let url = "https://access.line.me/oauth2/v2.1/authorize?response_type=code"
                        + "&client_id=" + channelId
                        + "&redirect_uri=" + encodedCallbackUrl
                        + "&state=" + state
                        + "&scope=" + scope
                        + "&nonce=" + nonce;
          
            let new_window = this.$tools.popupwindow(url,'', 550, 570);
        },

        signInWithZalo() {
            localStorage.removeItem("currentLocale");
            localStorage.setItem("currentLocale", this.currentLocale);
            
            signInZalo.redirectToZaloLoginPage();
        },

        // listenKeypressEvent(e) {
        //      if (e.key === "Enter") {
        //        this.postSigninData();
        //     } 
        // }
      }
}
</script>

<style>
.signin-form__forgetpassword{
    color: var(--bg-selected);
    text-decoration: underline;
    font-size: 0.6875rem;
    cursor: pointer;
}
.signin-form__OTP{
    text-align: center;
    margin-top: 1rem;
    font-size: 0.875rem;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}
</style>